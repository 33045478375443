#homepage .social_icons_outer {
    display: flex;
}

#homepage .social_icon_wrap {
    flex-basis: 2.4rem;
    width: 2.4rem;
    flex-shrink: 0;
}

#homepage .horizontal.social_icons_outer {
    gap: 2.4rem;
}

#homepage .vertical.social_icons_outer {
    flex-direction: column;
    gap: 2rem;
}