.splide_caption_image {
    width: 2rem;
    min-height: 2.2rem;
    background-image: url('../public/assets/images/icon/map-pin.png');
    background-repeat: no-repeat;
    background-size: contain;
}

/*
.slider_carousel-locations begins
{vegan plates worldwide, currently live in}
*/

.slider_carousel-locations {
    margin-top: 4rem;
    margin-bottom: 6.8rem;
}

.slider_carousel-locations .splide__pagination {
    bottom: -4rem;
}

.slider_carousel-locations .splide__pagination__page.is-active {
    background-color: #EA97C3;
    transform: unset;
}

.slider_carousel-locations .splide__pagination__page {
    width: 0.8rem;
    height: 0.8rem;
}

.slider_carousel-locations .splide__pagination li {
    padding: 0rem 0.3rem;
}

.slider_carousel-locations .splide_caption {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.2rem;
    margin-bottom:0.6rem;
}

.slider_carousel-locations .splide_caption_text {
    font-size: 2.4rem;
    margin-left: 0.85rem;
}

.slider_carousel-locations .splide__slide img {
    border-radius: 1rem !important;
}

/*
.slider_carousel-locations ends
*/


/*
.slider_carousel-plates begins
{vSupporting all restaurants that serve vegan plates}
*/

.slider_carousel-plates  {
    /* margin-top: 4rem; */
}

.slider_carousel-plates .splide__list {
    margin: 4.75rem 0rem 4rem !important;
    padding-right: 5rem !important;
}

.slider_carousel-plates .splide__pagination {
    bottom: -1.5rem;
}

.slider_carousel-plates .splide__pagination__page.is-active {
    background-color: #EA97C3;
    transform: unset;
}

.slider_carousel-plates .splide__pagination__page {
    width: 0.8rem;
    height: 0.8rem;
}

.slider_carousel-plates .splide__pagination li {
    padding: 0rem 0.3rem;
}

.slider_carousel-plates .splide_caption {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    padding: 2.2rem 2rem 1.7rem;
    text-align: left;
}

.slider_carousel-plates .splide_caption_title {
    font-size: 1.6rem;
    font-weight: 400;
    color: #080809;
}

.slider_carousel-plates .splide_caption_restaurant {
    font-size: 1.6rem;
    font-style: italic;
    padding: 0.5rem 0rem 0rem;
    display: block;
}

.slider_carousel-plates .splide__slide {
    background: #FFFFFF;
    border: 1px solid #F2F2F3;
    box-sizing: border-box;

    box-shadow: 0px 20px 20px rgba(157, 164, 170, 0.15);
    border-radius: 8px;
}

.slider_carousel-plates .splide__slide img {
    /* border-radius: 1rem !important; */
}

.slider_carousel-plates .carousel-plates-image-wrap {
    /* width: 27.1rem; */
}

/*
.slider_carousel-plates ends
*/

/*
.slider_carousel-comingsoon begins
{vegan plates worldwide, coming soon in}
*/

.slider_carousel-comingsoon {
    margin: 0rem -5rem;
    position: relative;
}


.slider_carousel-comingsoon .splide__list {
    padding-bottom: 1rem !important;
}

.slider_carousel-comingsoon .overlay-1-left {
    position: absolute;
    left: 5rem;
    height: 100%;
    width: 7.5rem;
    top: 0;
    background-image: url("../public/assets/images/overlay/overlay-1-left.png");
    background-repeat: no-repeat;
    z-index: 1;
}

.slider_carousel-comingsoon .overlay-1-right {
    position: absolute;
    height: 100%;
    width: 7.5rem;
    right: 0rem;
    top: 0;
    background-image: url("../public/assets/images/overlay/overlay-1-right.png");
    background-repeat: no-repeat;
    z-index: 2;
    background-position: right;
}

.slider_carousel-comingsoon .splide_caption_dot_wrap {
    height: 100%;
    display: flex;
}

.slider_carousel-comingsoon .splide_caption_dot {
    height: 0.4rem;
    width: 0.4rem;
    border-radius: 50%;
    background-color: #787887;
    display: block;
    margin: auto;
}

.slider_carousel-comingsoon .splide_caption {
    display: flex;
}

.slider_carousel-comingsoon .splide_caption_text {
    font-size: 2.4rem;
}

.slider_carousel-comingsoon .splide_caption_image {
    width: 1.8rem;
    background-position: center;
    margin-right: 1.15rem;
    margin-top: -0.2rem;
}

/*
.slider_carousel-comingsoon ends
*/