@media (min-width: 769px){
    #homepage .footer-wrap > div {
        position:relative;
    }

    #homepage .footer-wrap > div + div::after {
        content: "";
        background: #E4E4E7;
        position: absolute;
        bottom: 10%;
        left: 0;
        height: 80%;
        width: 0.09rem;
    }    
}

@media (max-width: 768px){
    #homepage .footer-wrap {
        flex-direction: column;
        align-items: center;
        gap: 2.4rem;
    }

    #homepage .footer_logo_image_wrap {
        width: 4rem;
    }

    #homepage .footer-one {
        flex-basis: auto;
    }

    #homepage .footer-two {
        flex-basis: auto;
        border: none;
        font-size: 1.3rem;
    }

    #homepage .footer-three {
        flex-basis: auto;
    }

    #homepage .footer-copyright {
        margin-top: 2.4rem;
    }
}