#homepage .section_1 {
    background-image: url('../public/assets/images/bg-1.png');
    background-repeat: no-repeat;
    background-position: top right;
    min-height: 60rem;
    background-size: 75vw;
}

#homepage .section_1_wrap {
    display: flex;
    margin-top: 4.6rem;
}

#homepage .section_1_left {
    flex-basis: 58rem;
    flex-grow:0;
    margin-top: 11.8rem;
    flex-shrink: 0;
}

#homepage .section_heading_underline {
    width: 20rem;
    border-top: 1px solid #080809;
    margin: 2rem 0rem;
    display: block;
}

#homepage .section_1_right {
    flex-basis: 57.5rem;
    flex-grow:1;
    display: flex;
    justify-content: flex-end;
    padding-left: 2rem;
}

#homepage .section_1_right_image_wrap {
    width: 63.2rem;
}

#homepage .section_1_social_outer {
    display: flex;
    justify-content: flex-end;
    margin-left: -2.75rem;
    width: 5.3rem;
    margin-top: 22.25rem;
}

#homepage .section_1_four {
    justify-content: space-evenly;
    margin-top: 2.4rem;
}

#homepage .section_1_mobile_appstore_wrap {
    width: 13.8rem;
}

#homepage .section_1_mobile_playstore_wrap {
    width: 13.8rem;
}

#homepage .section_2 {
    background-color: #F9F8F6;
    padding-top: 7.5rem;
    padding-bottom: 9rem;
}

#homepage .section_2_wrap {
    display: flex;
}

#homepage .section_2_left {
    flex-basis: 57.5rem;
    flex-grow:1;
    display: flex;
    justify-content: flex-start;
}

#homepage .section_2_left_image_wrap {
    width: 56.25rem;
    margin-left: -1.9rem;
}

#homepage .section_2_right {
    flex-basis: 57.3rem;
    flex-grow:0;
    margin-top: 19.6rem;
}

#homepage .section_3 {
    background-image: url('../public/assets/images/bg-3.png');
    background-repeat: no-repeat;
    background-position: top right;
    min-height: 45.3rem;
    background-size: cover;
    color: #ffffff;
    text-align: center;
}

#homepage .section_3_heading {
    color: #ffffff;
    padding-top: 15rem;
}

#homepage .section_3_heading_underline {
    margin: 2rem auto;
    border-color: #ffffff;
}

#homepage .section_3_three {
    width: 46%;
    margin: 0rem auto;
}

#homepage .section_4 {
    background-color: #F9F8F6;
    padding-bottom: 3.3rem;
}

#homepage .section_4_container-full {
    width: 82%;
    margin: 0rem auto;
}

#homepage .section_4_wrap {
    display: flex;
    padding-top: 5rem;
}

#homepage .section_4_left {
    margin-top: 8.5rem;
    flex-basis: 50rem;
    flex-shrink: 0;
}

#homepage .section_4_right {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: -5rem;
}

#homepage .section_5 {
    text-align: center;
    padding: 7rem 0rem 0rem;
}

#homepage .section_5_container-full {
    width: 82%;
    margin: 0rem auto;
}

#homepage .section_5_heading_underline {
    margin: 2rem auto;
}

#homepage .section_5_five {
    color: #080809;
    font-size: 3.2rem;
    margin: 4.8rem auto 7.7rem;
}

#homepage .carousel-locations-image-wrap {
    height: 29.1rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 1rem;
}

#homepage .section_5_six {
    padding-bottom: 10.9rem;
    overflow-x: hidden;
}

#homepage .section_6 {
    background-image: url('../public/assets/images/bg-6.png');
    background-repeat: no-repeat;
    background-position: top right;
    min-height: 40.3rem;
    background-size: cover;
    color: #ffffff;
    text-align: center;
    padding-top: 8.7rem;
}

#homepage .section_6_heading {
    margin: 3.2rem auto;
    font-size: 3.2rem;
    color: #ffffff;
    width: 75rem;
    line-height: 4.2rem;
}

#homepage .section_6_heading_underline {
    border-color: #ffffff;
    margin: 0rem auto;
}

#homepage .section_6_three {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 3.2rem 0rem 8.7rem;
}

#homepage .section_6_appstore_white_wrap {
    width: 13.8rem;
}

#homepage .section_6_playstore_white_wrap {
    width: 13.8rem;
}
