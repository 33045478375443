.section_1,
.section_2,
.section_3,
.section_4x {
  /* display: none; */
}

@media (min-width: 769px){
  .desktop-display-none {
    display: none;
  }
}

@media (max-width: 768px){
    html {
        font-size: 2.67vw !important;
    }

    #homepage  {
      font-size: 1.4rem;
      line-height: 2.1rem;
    }

    #homepage .section_heading {
      font-size: 3.2rem;
      line-height: 4.16rem;
    }
    
    #homepage .container {
      width: 91.5%;
    }

    #homepage .mobile-display-block {
      display: block;
    }

    #homepage .mobile-display-none {
      display: none;
    }

    #homepage .mobile-display-flex {
      display: flex;
    }
}