/*
375px width html: 2.67vw
*/

/* mobile styles */

@media (max-width: 768px) {

    #homepage .section_1 {
        background-size: contain;
    }

    #homepage .section_1_wrap {
        flex-direction: column-reverse;
        text-align: center;
        margin: 0rem auto 4rem;
    }

    #homepage .section_1_left {
        margin-top: unset;
        flex-basis: 35rem;
    }

    #homepage .section_1_right {
        flex-basis: auto;
        overflow: hidden;
    }

    #homepage .section_1_right_image_wrap {
        margin: -1rem -3.8rem -1.5rem -3.8rem;
    }

    #homepage .section_1_social_outer {
        margin-right: unset;
        width: auto;
        position: absolute;
        right: 1.7rem;
        margin-top: 13.3rem;
    }

    #homepage .section_heading_underline {
        margin: 2rem auto;
    }

    #homepage .section_2 {
        text-align: center;
        padding-top: 3rem;
        padding-bottom: 4rem;
    }

    #homepage .section_2_wrap {
        flex-direction: column;
    }

    #homepage .section_2_left_image_wrap {
        margin-left: -1.2rem;
        margin-bottom: 1rem;
    }

    #homepage .section_2_left {
        flex-basis: auto;
    }

    #homepage .section_2_right {
        margin-top: unset;
        flex-basis: 15rem;
    }

    #homepage .section_3 {
        background-image: url('../public/assets/images/bg-3-mobile.png');
    }
    
    #homepage .section_3_heading {
        padding-top: 16.5rem;
    }

    #homepage .section_3_three {
        width: auto;
        margin: 0rem auto;
    }    

    #homepage .section_4 {
        text-align: center;
    }

    #homepage .section_4_container-full {
        width: 100%;
    }

    #homepage .section_4_wrap {
        flex-direction: column-reverse;
        padding-top: 3rem;
    }

    #homepage .section_4_left {
        margin: 5.2rem auto 0rem;
        width: 91.5%;
        flex-basis: 35rem;
    }

    #homepage .section_4_left_button {
        margin-top: 2.5rem;
    }

    #homepage .section_4_right {
        margin-right: 0rem;
    }

    #homepage .section_5 {
        padding: 4rem 0rem 0rem;
    }

    #homepage .section_5_container-full {
        width: 100%;
    }

    #homepage .section_5_five {
        font-size: 2.4rem;
        margin-top: 7rem;
        margin-bottom: 4rem;
    }

    #homepage .carousel-locations-image-wrap {
        height: 20.3rem;
    }

    #homepage .section_5_six {
        padding-bottom: 5.2rem;
    }    

    #homepage .section_6 {
        background-image: url('../public/assets/images/bg-6-mobile.png');
        min-height: 40.3rem;
        display: flex;
        padding-top: 6.8rem;
        min-height: unset;
        padding-bottom: 8.7rem;
    }

    #homepage .section_6_heading {
        width: 100%;
        margin: 0rem auto;
        line-height: 4.16rem;
    }

    #homepage .section_6_three {
        padding: 0rem;
    }
}