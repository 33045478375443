@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');
/*
font-family: 'Yeseva One', cursive;
*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

#homepage {
  font-family: 'Poppins', sans-serif;
  color: #787887;
  font-size:1.6rem;
  font-weight: 300;
  line-height: 2.46rem;
}

#homepage a {
  color: inherit;
  text-decoration: none;
}

#homepage .heading, .font-secondary {
  font-family: 'Yeseva One', cursive;
}

#homepage .section_heading {
  font-size: 5.6rem;
  color: #080809;
  line-height: 7.3rem;
}

#homepage .container {
  /* max-width: 1440px; */
  width: 82%;
  margin: 0 auto;
}

#homepage .container-full {
  width: 100%;
}

#homepage .color-pink {
  color: #EA97C3;
}

#homepage .color-green {
  color: #28755B;
}

#homepage .button {
  padding: 1.2rem 2.25rem;
  border-radius: 0.8rem;
  font-size: 1.4rem;
  font-weight: 400;
}

#homepage .button-pink {
  background-color: #EA97C3;
  color: #ffffff;
}

#homepage .section_4_left_button {
  margin: 3.2rem 0rem;
}

#homepage .display-inline-block {
  display: inline-block;
}

