@media (min-width: 769px) {
    .slider_carousel-locations .splide__list,
    .section_5_four .splide.is-initialized:not(.is-active) .splide__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .slider_carousel-locations .splide__slide {
        flex-basis: 57.4rem;
        margin-bottom: 3.2rem;
    }
    
    .slider_carousel-plates .splide__list,
    .section_4_right .splide.is-initialized:not(.is-active) .splide__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .slider_carousel-plates .splide__slide {
        flex-basis: 26.9rem;
        /* margin-bottom: 3.2rem; */
        height: fit-content;
    }

    .slider_carousel-plates .splide__slide:nth-child(1){
        margin-top: 0;
    }

    .slider_carousel-plates .splide__slide:nth-child(even){
        margin-top: 3.2rem;
        margin-left: 3.2rem;
    }

    .slider_carousel-plates .splide__slide:nth-child(odd){
        margin-top: -4.2rem;
    }

    .slider_carousel-plates .carousel-plates-image-wrap {
        width: 26.9rem;
    }    
}

@media (max-width: 768px) {

    .splide_caption_image {
        min-height: 1.8rem;
    }
    .slider_carousel-comingsoon .splide_caption_text {
        font-size: 1.6rem;
    }

    .slider_carousel-comingsoon .overlay-1-left {
        width: 3.8rem;
        background-image: url("../public/assets/images/overlay/overlay-1-left-mobile.png");
    }
    
    .slider_carousel-comingsoon .overlay-1-right {
        width: 3.8rem;
        background-image: url("../public/assets/images/overlay/overlay-1-right-mobile.png");
    }

    .slider_carousel-plates .splide__list {
        padding-right: unset !important;
    }

    .slider_carousel-plates .splide_caption_restaurant {
        padding-top: 0.6rem;
        padding-bottom: 0.5rem;
    }

    .slider_carousel-plates  .splide__pagination {
        margin-top: 0rem;
    }

    .slider_carousel-locations {
        margin-bottom: 9rem;
    }

    .slider_carousel-locations .splide_caption_text {
        font-size: 1.6rem !important;
    }

    .slider_carousel-locations .splide__pagination {
        bottom: -5rem;
    }    

}