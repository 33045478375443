#homepage .footer {
    background-color: #F9F8F6;
    padding: 3.5rem 0rem 1rem;
}

#homepage .footer_logo_image_wrap {
    width: 6.9rem;
}

#homepage .footer-wrap {
    display: flex;
}

#homepage .footer-one {
    flex-basis: 29rem;
    flex-grow: 1;
}

#homepage .footer-two {
    flex-basis: 57.3rem;
    flex-grow: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
}

#homepage .footer-links-separator {
    width: 3.2rem;
}

#homepage .footer-three {
    flex-basis: 29rem;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
}

#homepage .footer-copyright {
    text-align: center;
    font-size: 1.2rem;
    opacity: 0.5;
}