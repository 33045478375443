@media (max-width: 768px){
    #homepage .header {
        margin-top: 2.5rem;
        justify-content: space-between;
    }

    #homepage .logo-image-div {
        flex-basis: 4rem;
    }

    #homepage .logo-text {
        display: none;
    }

    #homepage .tagline {
        position: absolute;
        left: 0;
        top: 0;
        background-color: #EA97C3;
        color: #ffffff;
        width: 100%;
        font-size: 1.2rem;
        padding: 0.2em 0rem;
    }

    #homepage .header-right {
        display: none;
    }

    #homepage .icon-burger-div {
        width: 2.4rem;
        margin-top: 0.8rem;
        cursor: pointer;
    }
}