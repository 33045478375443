
#homepage .header {
    display: flex;
    padding-top: 1.6rem;
}

#homepage .logo {
    flex-basis:25%;
}

#homepage .logo-a {
    display: flex;
}

#homepage .logo-image-div {
    flex-basis: 4.8rem;
    flex-shrink: 0;
}

#homepage .logo-text {
    display: flex;
    align-items: center;
    margin-left: 1.6rem;
    color: #080809;
    font-weight: 400;
}

#homepage .tagline {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

#homepage .header-right {
    flex-basis: 25%;
    display: flex;
    gap: 2rem;
    justify-content: flex-end;
}

#homepage .header-right-bttn {
    flex-basis: 13.8rem;
}

#homepage .header-burger {
    display: none;
}

